export enum COLLECTION {
    USERS = 'users',
    POSTS = 'posts',
    NEWS = 'news',
    COURS = 'cours',
    FILES = 'files',
    FORFAITS = 'forfaits',
    GALLERY = "gallery",
    PLANNING = 'planning',
    GENERAL_INFORMATIONS= 'general_informations',
    BOOKMARKS = 'bookmarks',
    TEMOIGNAGES = 'temoignages'
} 