import React from "react"
import AdminPlanning from "../../../Components/Planning/AdminPlanning"

export const PlanningPage = () => {
  return (
    <>
      <AdminPlanning />
    </>
  )
}
